import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets

const {baseURL,proName} = require("@/util/request.js");

import ElementUI from 'element-ui'
import './theme/index.css'
Vue.use(ElementUI)
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// import './assets/font/fontsize/iconfont.css'
import './style/index.scss' // global css
import local from './util/local'
Vue.prototype.$local = local
import util from './util/util'
Vue.prototype.$util = util

import api from '@/api/api'
Vue.prototype.$api = api
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.prototype.$baseURL= baseURL;
import md5 from 'js-md5';
Vue.prototype.$md5= md5;
import QRCode from 'qrcodejs2'
Vue.prototype.$QRCode= QRCode;

let hasSide = 'true'
Vue.prototype.$hasSide = hasSide
Vue.config.productionTip = false
router.beforeEach((to,from,next)=>{
	document.title = proName
	next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
